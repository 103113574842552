.wrapper {
  display: flex;
  flex-direction: column;
  @include media(PC){
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: $width;
    width: 100%;
    margin: 20px auto;
  }
  .single-poplur-title {
    padding: 10px;
    margin-bottom: 20px;
    @include media(PC){
      padding: 0;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 2.0rem;
      font-weight: bold;
      border-bottom: 2px solid #000;
      @include media(PC){
        font-size: 2.6rem;
        padding: 10px 0 10px;
        border-bottom: 2px solid #000;
      }
      span {
        font-size: 1.6rem;
        @include media(SP){
          font-size: 2.0rem;
        }
      }
    }
  }
  .popular-single-main {
    margin: 20px 0 0;
    @include media(PC){
      width: calc(100% - 260px);
      margin: 0;
    }
  }
  #sub {
    @include media(PC){
      width: 220px;
    }
  }
  .single-poplur-k_list {
    margin-bottom: 20px;
    padding: 0 10px;
    .inr {}
    .poplur-col__list {
      .poplur-col__list_col {
        background-color: #F4F4F4;
        margin-bottom: 30px;
        @include media(PC){
          display: flex;
          align-items: stretch;
        }
        &:before {
          content:'NEW';
          background-color: #ccc;
          padding: 5px 10px;
          display: block;
          font-size: 1.2rem;
          font-weight: bold;
          color: #fff;
          @include media(PC){
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: 80px;
            font-size: 1.4rem;
          }
        }
        &:nth-of-type(1):before{
          background-color: #D3B246;
        }
        &:nth-of-type(2):before{
          background-color: #888888;
        }
        &:nth-of-type(3):before{
          background-color: #D14000;
        }
        a {
          display: block;
          color: #000;
          text-decoration: none;
          width: 100%;
        }
        .poplur-col-col2 {
          display: flex;
          padding: 10px;
          .image {
            width: 130px;
            @include media(PC){
              width: 200px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          .poplur-col__contents {
            margin-left: 10px;
            width: calc(100% - 140px);
            @include media(PC){
              width: calc(100% - 220px);
            }
            .poplur-col__header {
              .title {
                position: relative;
                .name {
                  margin-bottom: 10px;
                  h3 {
                    font-size: 1.8rem;
                    font-weight: bold;
                    @include media(PC){
                      font-size: 2.0rem;
                      line-height: 1.4;
                      padding-right: 60px;
                    }
                  }
                  .kana {
                    font-size: 1.2rem;
                    @include media(PC){
                      font-size: 1.4rem;
                    }
                  }
                }
                .area {
                  position: absolute;
                  top: -35px;
                  right:0px;
                  background-color: #fff;
                  padding: 2px 4px;
                  border: 1px solid #ccc;
                  @include media(PC){
                    top: 0;
                    right: 0;
                    font-size: 1.5rem;
                    padding: 5px 10px;
                    border: 1px solid #ccc;
                    font-weight: bold;
                  }
                }
              }
            }
            .copy {
              font-size: 1.2rem;
              font-weight: bold;
              margin-bottom: 10px;
              @include media(PC){
                font-size: 1.4rem;
              }
              br {
                display: none;
              }
            }
            .adr {
              font-size: 1.0rem;
              @include media(PC){
                font-size: 1.2rem;
              }
            }
            .seat {
              font-size: 1.0rem;
              @include media(PC){
                font-size: 1.2rem;
              }
            }
          }
        }
        .poplur-col__footer {
          @include media(PC){
            display: flex;
          }
          .photo {
            display: none;
            @include media(PC){
              display: inherit;
              padding: 0 10px;
            }
            ul {
              display: flex;
              li {
                width: 50%;
                @include media(PC){
                  width: 100px;
                }
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
          .info {
            display: flex;
            flex-wrap: wrap;
            @include media(PC){
              width: 100%;
              padding-right: 10px;
              padding-bottom: 10px;
            }
            div {
              width: calc(50% - 4px);
              background-color: #fff;
              margin: 2px;
              padding: 5px 10px;
              font-size: 1.4rem;
              @include media(PC){
                font-size: 1.6rem;
              }
              span {
                display: block;
                font-size: 1.0rem;
                text-align: left;
              }
              &.recruit {
                background-color: #d73d3d;
                color: #fff;
                font-weight: bold;
                span {
                  font-weight: normal;
                }
              }
            }

          }
        }
      }
    }
  }
  .single-poplur-n_list {
    margin-bottom: 20px;
    padding: 0 10px;
    ul {
      li {
        // border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 0 3px rgba(#000,0.2);
        margin-bottom: 20px;
        a {
          display: block;
          text-decoration: none;
          color: #000;
          font-size: 1.2rem;
        }
        .name {
          // border-bottom: 1px solid #ccc;
          background-color: #eee;
          padding: 5px;
          padding-left: 10px;
          font-size: 1.6rem;
        }
        .single-poplur-col {
          display: flex;
          padding: 10px;
          .image {
            width: 90px;
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
          .info {
            padding: 10px;
            font-size: 1.1rem;
            width: calc(100% - 90px);
            div {
              border-bottom: 1px dotted #eee;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
