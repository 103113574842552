//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html, body {
    font-size: 62.5%;
    font-family: $body-font;
    color: $dark-color;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
}

::selection {
    background: $primary-color;
    color: $light-color;
    text-shadow: none;
}


[v-cloak] {
  display:none;
}

//////////////////////////////////////////////////////////
// 2. animation
//////////////////////////////////////////////////////////

.fade-enter-active, .fade-leave-active {
  transition: all .5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


.p-popup {
  background-color: #f8b62b;
  text-align: center;
  padding: 10px 20px;
  font-size: 1.4rem;
  color : #000;
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 10px;
  }
}

//////////////////////////////////////////////////////////
// 2. header style
//////////////////////////////////////////////////////////

#header {
  background-color: #d73d3d;
  border-top: 4px solid #D7B472;
  border-bottom: 4px solid #D7B472;
  .inr {
    position: relative;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 10px 0;
    }
  }
  .header-logo {
    padding: 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(PC){
      width: 250px;
      margin-right: 30px;
    }
    a {
      text-decoration: none;
      color: #fff;
    }
    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
      @include media(PC){
        text-align: left;
      }
      span {
        font-size: 1.0rem;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  .gnav {
    display: none;
    @include media(PC){
      display: inherit;
      width: 720px;
    }
    &.open {
      display: inherit;
    }
    .gnav__list {
      ul {
        display: flex;
        flex-direction: column;
        @include media(PC){
          flex-direction: row;
        }
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #fff;
          @include media(PC){
            margin-right: 30px;
            border-top: 0px solid #000;
          }
          a {
            color: $light-color;
            text-decoration: none;
            font-weight: 800;
            font-size: 1.2rem;
            padding: 10px 5px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: flex-start;

            @include media(PC){
              justify-content: flex-start;
              font-size: 1.4rem;
              padding: 20px 2px;
            }
          }
        }
      }
    }
  }
  .header-tel {
    background-color: #fff;
    padding: 10px 30px;
    border-radius: 30px;
    display: none;
    @include media(PC){
      display: inherit;
      margin-left: 0px;
    }
    a {
      color: #d73d3d;
      font-size: 1.6rem;
      text-decoration: none;
    }
  }
}

.menu {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 53px;
  width: 35px;
  z-index: 9999;
  span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  @include media(PC){
    display: none;
  }
}
.menu .menu-line {
  position: absolute;
  width: 35px;
  left: 0px;
  height: 3px;
  border-radius: 2px;
  background: #fff;
  }
   .menu .menu-line:nth-of-type(1) {
  top: 8px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  }
   .menu .menu-line:nth-of-type(2) {
  top: 16px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  }
   .menu .menu-line:nth-of-type(3) {
  top: 24px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  }
   .menu.open .menu-line {
  background: #fff;
  }
   .menu.open .menu-line:nth-of-type(1) {
  top: 16px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  }
   .menu.open .menu-line:nth-of-type(2) {
  opacity: 0;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  }
   .menu.open .menu-line:nth-of-type(3) {
  top: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  }


// footer
#footer {
  background-color: rgb(63, 63, 63);
  color: #fff;
  .footer-copy {
    padding: 20px 20px 0;
    text-align: center;
    font-size: 1.1rem;
    line-height: 2;
    margin-bottom: 20px;
    @include media(PC){
      font-size: 1.4rem;
      padding: 40px 40px 10px;
    }
  }
  .footer-info {
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 10px 0 40px;
    }
    ul {
      display: flex;
      flex-direction: column;
      @include media(PC){
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      li {
        margin-bottom: 15px;
        text-align: center;
        font-size: 1.4rem;
        @include media(PC){
          width: 300px;
          font-size: 2.0rem;
          text-align-last: left;
          line-height: 1;
        }
        a {
          font-size: 2.0rem;
          font-weight: bold;
          text-decoration: none;
          color:#fff;
          @include media(PC){
            font-size: 4.0rem;
          }
        }
      }
    }
  }
  #copyright {
    text-align: center;
    background-color: rgb(21, 21, 21);
    font-size: 1.2rem;
    padding: 10px;
  }
}


//
#breadcrumb {
  background-color: #eee;
  @include media(PC){
    margin-bottom: 60px;
  }
  ul {
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto 0px;
      display: flex;
      align-items: center;
      padding: 5px 0;
    }
    li {
      margin-right: 10px;
      font-size: 1.2rem;
      a {
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
