.shop-single_header {
  padding: 10px;
  .inr {
    padding: 10px;
    border: 1px solid #d73d3d;
    border-top: 10px solid #d73d3d;
    margin: 20px 0 0;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0px auto;
      padding: 40px;
    }
  }
  .title {
    h1 {
      .adr {
        font-size: 1.2rem;
        @include media(PC){
          font-size: 1.4rem;
          margin-bottom: 10px;
        }
      }
      .name {
        font-size: 2.0rem;
        font-weight: bold;
        @include media(PC){
          font-size: 3.6rem;
        }
        span {
          font-size: 1.2rem;
          font-weight: normal;
          @include media(PC){
            font-size: 1.8rem;
          }
        }
      }
    }
  }
  .info {
    padding: 10px 0 10px;
    @include media(PC){
      padding: 0;
      margin-top: 20px;
    }
    ul {
      display: flex;
      .info-col {
        margin-right: 5px;
        @include media(PC){
          margin-right: 20px;
          font-size: 1.4rem;
          line-height: 1;
        }
        span {
          background-color: #d73d3d;
          color: #fff;
          padding: 5px;
          margin-right: 5px;
          @include media(PC){
            padding: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.shop-signle_gallery {
  .inr {
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto;
      padding: 10px 0;
    }
  }
  .title {
    h2 {
      font-size: 1.6rem;
      background-color: #666;
      color: #fff;
      text-align: center;
      margin: 10px 10px 10px;
      padding: 5px 0;

      @include media(PC){
        font-size: 2.0rem;
        margin: 0px 0 20px;
      }
    }
  }
  .lightbox {
    li {
      padding: 0 10px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  button{
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .slide-dots{
    text-align: center;
    li{
      display: inline-block;
      margin: 0 15px;
      button{
        position: relative;
        text-indent: -9999px;
        &:before{
          content: '●';
          font-size: 20px;
          text-indent: 0px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.25;
        }
      }
      &.slick-active{
        button:before{
          content: '●';
          opacity: 1;
        }
      }
    }
  }
}
.shop-single_text {
  .inr {
    padding: 20px;
    background-color: #eee;
    margin: 10px;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto 40px;
    }
  }
  h3 {
    text-align: center;
    font-size: 2.0rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.8;
    text-align: center;
    @include media(PC){
      font-size: 1.4rem;
    }
  }
  .figure {
    padding: 20px;
    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
}
.shop-single_etc {
  .inr {
    padding: 20px;
    background-color: #eee;
    margin: 10px;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto 40px;
    }
  }

}
.shop-single_price {
  .inr {
    margin: 20px 0;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto 40px;
      padding: 10px 0;
    }
  }
  .title {
    h2 {
      font-size: 1.6rem;
      background-color: #666;
      color: #fff;
      text-align: center;
      margin: 10px 10px 10px;
      padding: 5px 0;

      @include media(PC){
        font-size: 2.0rem;
        margin: 0px 0 20px;
      }
    }
  }
  .table {
    padding: 0 10px;
    @include media(PC){
      padding: 0;
    }
    table {
      border: 1px solid #ccc;
      width: 100%;
      tr {
        &:first-child {
          background-color: #eee;
          border-bottom: 1px solid #ccc;
        }
      }
      td {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        text-align: center;
        padding: 5px;
        font-size: 1.2rem;
        @include media(PC){
          font-size: 1.4rem;
        }
      }
    }
  }
}
.shop-single_info {
  // background-color: #fff;
  .inr {
    margin: 20px 0;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto 40px;
      padding: 10px 0;
    }
  }
  .title {
    h2 {
      font-size: 1.6rem;
      background-color: #666;
      color: #fff;
      text-align: center;
      margin: 10px 10px 10px;
      padding: 5px 0;

      @include media(PC){
        font-size: 2.0rem;
        margin: 0px 0 20px;
      }
    }
  }
  .col2 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 10px;
    @include media(PC){
      flex-direction: row;
      padding: 0;
    }
  }
  .shop-single_info__left {
    margin-bottom: 20px;
    @include media(PC){
      width: calc(100% - 420px);
    }
    dl {
      display: flex;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      font-size: 1.2rem;
      align-items: center;
      justify-content: center;
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      @include media(PC){
        font-size: 1.4rem;
      }
      dt {
        width: 30%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      dd {
        width: 70%;
        padding: 5px;
      }
    }
  }
  .shop-single_info__right {
    @include media(PC){
      width: 400px;
    }
  }

}
.shop-single_recruit {
  background-color: #d73d3d;
  color: #fff;
  margin-bottom: 40px;
  .inr {
    margin: 20px 0;
    padding: 40px 0;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto;
      padding: 80px 0;
    }
  }
  .title {
    h2 {
      font-size: 1.6rem;
      background-color: #D7B472;
      color: #fff;
      text-align: center;
      margin: 10px 10px 10px;
      padding: 5px 0;

      @include media(PC){
        font-size: 2.0rem;
        margin: 0px 0 20px;
      }
    }
  }
  .shop-single_recruit__col {
    .info {
      dl {
        display: flex;
        flex-direction: column;
        padding: 10px;
        @include media(PC){
          flex-direction: row;
          padding: 0;
        }
        dt {
          margin-bottom: 10px;
          .image {
            @include media(PC){
              width: 200px;
            }
            img {
              width: 100%;
              height: auto;
            }
            @include media(PC){
              margin-right: 20px;
            }
          }
        }
        dd {
          .copy {
            h3 {
              font-size: 1.6rem;
              margin-bottom: 20px;
              font-weight: bold;
              @include media(PC){
                font-size: 2.0rem;
              }
            }
          }
          .text {
            font-size: 1.2rem;
            @include media(PC){
              font-size: 1.4rem;
            }
          }
        }
      }
      .btn {
        margin: 10px;
        a {
          background-color: #D7B472;
          padding: 20px;
          color: #fff;
          padding: 10px;
          text-decoration: none;
          font-size: 2.0rem;
          font-weight: bold;
          display: block;
          text-align: center;
          border-radius: 40px;
          opacity: 1;
          transition: all .2s ease;
          position: relative;
          @include media(PC){
            padding: 20px;
            width: 600px;
            margin: 30px auto 40px;
          }
          &:hover {
            opacity: 0.7;
          }
          &:after {
            content:"\f105";
            position: absolute;
            font-family: fontawesome;
            right: 30px;
          }
        }
      }
    }
  }
}
.shop-single_relation {
  background-color: #eee;
  .inr {
    margin: 20px 0;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0 auto;
      padding: 80px 0;
    }
  }
  .title {
    h2 {
      font-size: 1.6rem;
      background-color: #666;
      color: #fff;
      text-align: center;
      margin: 10px 10px 10px;
      padding: 5px 0;

      @include media(PC){
        font-size: 2.0rem;
        margin: 0px 0 20px;
      }
    }
  }
  .list {
    padding: 10px;
    @include media(PC){
      padding: 0;
      margin-bottom: 40px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        @include media(PC){
          width: 25%;
        }
        a {
          display: block;
          text-decoration: none;
          color: #000;
          font-size: 1.2rem;
        }
        img {
          width: 100%;
          height: auto;
          padding: 5px;
        }
        .title {
          padding: 0 5px;
          .area {
            font-size: 1.0rem;
          }
          .name {
            font-weight: bold;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

.fixed-footer {
  position: fixed;
  bottom:0;
  left:0;
  width: 100%;
  background-color: rgba(#000,0.5);
  .inr {
    margin: 10px 0;
    line-height: 1;
    padding: 0;
  }
  a {
    background-color: #d73d3d;
    color: #fff;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    display: block;
    max-width: 300px;
    width: 100%;
    padding: 15px;
    margin: 0 auto;
    border-radius: 30px;
    &.btn-rec {
      background-color: #D7B472;
    }
    i {
      margin-right: 10px;
    }
  }
  .col2 {
    display: flex;
    padding: 0 10px;
    li {
      width: 50%;
      padding: 0 5px;
    }
  }
  @include media(PC){
    display: none;
  }
}


.popular-single {
  display: flex;
  flex-direction: row-reverse;
}
