#index {
  @import "pages/index";
}

#shop-single {
  @import "pages/single";
}

#popular-single {
  @import "pages/popular";
}

#sub {
  @import "pages/sub";
}

#shopcat-archive {
  @import "pages/shopcat";
}

#shop-archive {
  @import "pages/archive";
}
