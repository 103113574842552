.sub-popular {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  @include media(PC){
    padding: 0;
    margin-bottom: 40px;
  }
  .inr {
  }
  .title {
    background-color: #d73d3d;
    color: #fff;
    font-size: 1.6rem;
    padding: 10px;
    font-weight: bold;
  }
  ul {
    border-top: 1px solid #d73d3d;
    border-right: 1px solid #d73d3d;
    border-left: 1px solid #d73d3d;
    li {
      border-bottom: 1px solid #d73d3d;
      a {
        color: #000;
        font-size: 1.6rem;
        display: block;
        padding: 10px 30px 10px 20px;
        text-decoration: none;
        position: relative;
        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;
          content: "";
          vertical-align: middle;
          width: 8px;
          height: 8px;
          border-top: 2px solid #666;
          border-right: 2px solid #666;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}
.sub-area, .sub-genre {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  @include media(PC){
    padding: 0;
    margin-bottom: 40px;
  }
  .inr {
  }
  .title {
    background-color: #666;
    color: #fff;
    font-size: 1.6rem;
    padding: 10px;
    font-weight: bold;
  }
  ul {
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    border-left: 1px solid #666;
    li {
      border-bottom: 1px solid #666;
      a {
        color: #000;
        font-size: 1.6rem;
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        position: relative;
        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;
          content: "";
          vertical-align: middle;
          width: 8px;
          height: 8px;
          border-top: 2px solid #666;
          border-right: 2px solid #666;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

