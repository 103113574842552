.index-hero {
  background-color: #dbb677;
  background-image: url(./assets/img/bg01.png);
  background-repeat: repeat;
  background-size: 50px auto;
  .inr {
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0px auto;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
}
.index-popular {
  background-color: #eee;
  padding: 10px;
  @include media(PC){
    padding: 20px 0;
  }
  .inr {
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0px auto;
    }
  }
  .title {
    margin-top: 10px;
    margin-bottom: 20px;
    @include media(PC){
      margin-top: 20px;
      margin-bottom: 30px;
    }
    h2 {
      background-color: #d73d3d;
      padding: 5px 10px;
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      border-left: 10px solid #D7B472;
      @include media(PC){
        padding: 10px 20px;
        font-size: 2.4rem;
      }
    }
  }
  .list {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      @include media(PC){
        margin-left: -20px;
        padding: 15px 30px 0;
      }
      li {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-bottom: 10px;
        @include media(PC){
          width: calc(50% - 20px);
          margin-left: 20px;
          margin-bottom: 20px;
        }
        a {
          background-color: #fff;
          box-shadow: 0 0 4px rgba(#000, 0.2);
          padding: 20px 30px 20px 20px;
          display: flex;
          align-items: center;
          line-height: 1.5;
          font-size: 1.6rem;
          font-weight: bold;
          color: #d73d3d;
          text-decoration: none;
          border-left: 5px solid #d73d3d;
          border-radius: 4px;
          position: relative;
          transition: all .2s ease;
          @include media(PC){
            padding: 20px 0 20px 30px;
            font-size: 2.0rem;
            border-left: 8px solid #d73d3d;
            border-radius: 8px;
          }
          &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            content: "";
            vertical-align: middle;
            width: 8px;
            height: 8px;
            border-top: 2px solid #d73d3d;
            border-right: 2px solid #d73d3d;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:hover {
            background-color: #555;
            color: #fff;
          }
        }
      }
    }
  }
}
.index-category {
  // background-color: #eee;
  padding: 10px;
  @include media(PC){
    padding: 20px 0;
  }
  .inr {
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0px auto;
    }
  }
  .title {
    margin-top: 10px;
    margin-bottom: 20px;
    @include media(PC){
      margin-top: 20px;
      margin-bottom: 30px;
    }
    h2 {
      background-color: #555;
      padding: 5px 10px;
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      border-left: 10px solid #D7B472;
      @include media(PC){
        padding: 10px 20px;
        font-size: 2.4rem;
      }
    }
  }
  .list {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      @include media(PC){
        margin-left: -20px;
        padding: 15px 30px 0;
      }
      li {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-bottom: 10px;
        @include media(PC){
          width: calc(33% - 20px);
          margin-left: 20px;
          margin-bottom: 20px;
        }
        a {
          background-color: #fff;
          box-shadow: 0 0 4px rgba(#000, 0.2);
          padding: 20px 0px 20px 20px;
          display: flex;
          align-items: center;
          line-height: 1;
          font-size: 1.4rem;
          font-weight: bold;
          color: #555;
          text-decoration: none;
          border-left: 5px solid #555;
          border-radius: 4px;
          transition: all .2s ease;
          position: relative;
          @include media(PC){
            padding: 20px 0 20px 30px;
            font-size: 1.5rem;
            border-left: 8px solid #555;
            border-radius: 8px;
          }
          &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            content: "";
            vertical-align: middle;
            width: 8px;
            height: 8px;
            border-top: 2px solid #666;
            border-right: 2px solid #666;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:hover {
            background-color: #555;
            color: #fff;
          }
          i {
            padding-right: 20px;
          }
        }
      }
    }
  }
}
.index-sightseeing {
  background-color: #dbb677;
  background-image: url(./assets/img/bg01.png);

  background-repeat: repeat;
  background-size: 50px auto;
  .inr {
    padding: 20px 10px;
    @include media(PC){
      max-width: $width;
      width: 100%;
      margin: 0px auto;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
    .title {
      margin-top: 10px;
      margin-bottom: 20px;
      @include media(PC){
        margin-top: 20px;
        margin-bottom: 30px;
      }
      h2 {
        background-color: #d73d3d;
        padding: 5px 10px;
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        border-left: 10px solid #D7B472;
        @include media(PC){
          padding: 10px 20px;
          font-size: 2.4rem;
        }
      }
    }
    .image {
      padding: 10px;
    }
  }
}
